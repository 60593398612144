<template>
	<form class="main-wrapper mt-4">
		<CRow class="mb-5">
			<CCol md="12">
				<h4>General info</h4>
				<CInput
					v-model.trim="$v.localName.$model"
					:is-valid="!$v.localName.$error && null"
					:invalid-feedback="$t('global.error.required')"
					class="mt-4"
					label="Installment plan name (for back office)*"
					placeholder="E.g. Apple Krungsri installment plan"
				/>
			</CCol>
			<CCol md="12">
				<hr class="mb-4">
			</CCol>
			<CCol v-if="isEdit" md="12">
				<CRow>
					<CCol md="8">
						<h4>Installment plans</h4>
						<span class="typo-body-2 color-black-45">Create installment plans and condition such as interest rate, payments term, min-max order value, and brand code.</span>
					</CCol>
					<CCol md="4" class="text-right">
						<router-link
							:to="{
								name: 'BrandInstallmentPlanConditionCreate',
								params: {
									id: groupId,
									methodId: methodId,
									brandId: brandId,
								},
							}"
							:disabled="!isEdit"
							tag="button"
							class="btn btn-secondary"
						>
							<CIcon class="mr-2" name="cil-plus" /> Create plan
						</router-link>
					</CCol>
					<CCol md="12">
						<BaseNoItemBanner
							v-if="!plans.length"
							class="mt-4"
							text="There are no installment plan for this setting"
						/>
						<BaseTableInstallmentBAY
							v-else
							router-edit-name="BrandInstallmentPlanConditionEdit"
							:plans="plans"
							:group-id="groupId"
							:method-id="methodId"
						/>
						<hr class="my-5">
					</CCol>
				</CRow>
			</CCol>

			<!-- Available SKUs -->
			<CCol md="12">
				<CRow>
					<CCol md="12">
						<h4>Available SKUs</h4>
						<span class="typo-body-2 color-black-45">Set available SKUs for the installment plans above by specifying SKU or brand. Can't add SKU or brand that have already been used in other plans.</span>
					</CCol>
				</CRow>
			</CCol>
			<CCol md="12" class="mt-4 mb-5">
				<label class="label label-rule d-block">
					Add product by
				</label>
				<BaseButtonGroup
					:list="INSTALLMENT_PLAN_PRODUCT_BY_TYPE_OPTIONS"
					:value="localProductByType"
					@input="handleProductTypeChange"
				/>
			</CCol>
			<CCol md="12">
				<component
					:is="availableProductComponent"
					v-model="localAvailableProducts"
					:is-valid="!$v.localAvailableProducts.$error"
					:invalid-feedback="$t('global.error.required')"
					@onChange="handleAvailableProductChange"
				/>
				<hr class="mt-5 mb-5">
			</CCol>
			<!-- End Available SKUs -->
		</CRow>
		<BaseActionPanelStickyFooter
			:disabled-confirm="isSubmitting"
			:remove-text="isEdit ? 'Remove plan' : null"
			:is-edit="isEdit"
			content-class="main-wrapper"
			@onRemove="isEdit ? $refs['modal-remove'].open() : null"
			@onConfirm="handleSubmit"
			@onCancel="redirectToPaymentSetting"
		/>
		<BaseModalConfirmDelete
			ref="modal-confirm-discard"
			title="Discard?"
			description="You will be lost all added information. Are you sure to discard?"
			delete-button-text="Discard"
			@onSuccess="handleConfirmDiscard"
		/>
		<BaseModalConfirmDelete
			ref="modal-remove"
			:handle-remove="deleteInstallmentPlanGroup.bind(null, { methodId: methodId, brandId: brandId })"
			title="Remove this installment plan?"
			description="By removing this, the installment plan and condition will be removed from the system and will no longer available for customers on the Studio7 website."
			@onSuccess="redirectToPaymentSetting"
		/>
	</form>
</template>

<script>
import { mapActions } from 'vuex';
import { required } from 'vuelidate/lib/validators';
import FormAvailableProductBySKU from '@/components/FormAvailableProductBySKU.vue';
import FormAvailableProductByBrand from '@/components/FormAvailableProductByBrand.vue';
import BaseTableInstallmentBAY from '@/components/BaseTableInstallmentBAY.vue';
import {
	INSTALLMENT_PLAN_PRODUCT_BY_TYPE,
	INSTALLMENT_PLAN_PRODUCT_BY_TYPE_OPTIONS,
} from '../enums/payments';
import { ROUTE_NAME } from '../enums/route';

export default {
	name: 'FormBrandInstallmentPlan',

	validations() {
		return {
			localName: {
				required,
			},
			localAvailableProducts: {
				required,
			},
		};
	},
	components: {
		BaseTableInstallmentBAY,
	},
	props: {
		name: {
			type: String,
			default: null,
		},
		configBy: {
			type: String,
			default: null,
		},
		configData: {
			type: Array,
			default: () => [],
		},
		isSubmitting: {
			type: Boolean,
			default: false,
		},
		methodId: {
			type: [String, Number],
			default: null,
		},
		groupId: {
			type: [String, Number],
			default: null,
		},
		brandId: {
			type: [String, Number],
			default: null,
		},
		agent: {
			type: String,
			default: null,
		},
		isEdit: {
			type: Boolean,
			default: false,
		},
		gateway: {
			type: String,
			default: null,
		},
		plans: {
			type: Array,
			default: () => [],
		},
	},
	data() {
		return {
			INSTALLMENT_PLAN_PRODUCT_BY_TYPE_OPTIONS,
			localName: null,
			localProductByType: INSTALLMENT_PLAN_PRODUCT_BY_TYPE_OPTIONS[0].value,
			localAvailableProducts: [],
		};
	},
	computed: {
		availableProductComponent() {
			return this.localProductByType === INSTALLMENT_PLAN_PRODUCT_BY_TYPE.BRAND
				? FormAvailableProductByBrand
				: FormAvailableProductBySKU;
		},
	},
	mounted() {
		if (this.isEdit) {
			this.localName = this.name;
			this.localProductByType = this.configBy;
			this.localAvailableProducts = this.configData;
		}
	},
	methods: {
		...mapActions({
			deleteInstallmentPlanGroup: 'payments/deleteInstallmentPlanGroup',
		}),

		handleSubmit() {
			this.$v.$touch();

			if (this.$v.$invalid) {
				return;
			}

			const configData = this.localProductByType === INSTALLMENT_PLAN_PRODUCT_BY_TYPE.BRAND
				? this.localAvailableProducts.map((item) => item.id)
				: this.localAvailableProducts;

			const params = {
				name: this.localName,
				configBy: this.localProductByType,
				configData,
			};

			this.$emit('onSubmit', params);
		},
		redirectToPaymentSetting() {
			this.$router.push({
				name: ROUTE_NAME.PAYMENT_METHOD_SETTING,
				params: { id: this.groupId, methodId: this.methodId },
			});
		},
		handleRemovePlan() {
			this.redirectToPaymentSetting();
		},
		handleAvailableProductChange(list) {
			this.localAvailableProducts = list ? [...list] : [];
		},
		handleProductTypeChange(value) {
			if (this.localAvailableProducts.length) {
				this.$refs['modal-confirm-discard'].open();
			} else {
				this.localProductByType = value;
			}
		},
		handleConfirmDiscard() {
			this.localAvailableProducts = [];
			this.localProductByType = INSTALLMENT_PLAN_PRODUCT_BY_TYPE_OPTIONS
				.find((type) => type.value !== this.localProductByType).value;
		},
	},
};
</script>

<style lang="scss" scoped>
	::v-deep .input-period {
		.form-control-number {
			// override padding left append text
			padding-right: rem(60) !important;
		}
	}

	.table-installment {
		color: $color-black-90;

		tbody {
			box-shadow: 0 0 0 1px $color-gray-400;
			border-radius: 4px;
		}

		td,
		th {
			padding: rem(12);
		}

		tr {
			> td, > th {
				padding-left: rem(16);
			}
		}

		.w-20 {
			width: 20%;
		}
	}

	.icon-edit-plan {
		@include typo-body-2;

		cursor: pointer;
		color: $color-black-45;
		font-weight: 500;

		&:hover {
			color: $color-orange;
		}
	}
</style>
